import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'

const CheckOrderBox = ({ history }) => {
  const [keyword, setKeyword] = useState('')
  const submitHandler = (e) => {
    e.preventDefault()
    if (keyword.trim()) {
      history.push(`/order/${keyword}`)
    }
  }
  return (
    <React.Fragment>
      <div>
        <p>Check Order Status</p>
      </div>
      <Form onSubmit={submitHandler} inline>
        <Form.Control
          type='text'
          name='q'
          onChange={(e) => setKeyword(e.target.value)}
          placeholder='Phone or Order Id'
          className='mr-2 mb-md-0 mb-2'
        ></Form.Control>
        <Button type='submit' variant='success' className='p-2'>
          View Order
        </Button>
      </Form>
    </React.Fragment>
  )
}

export default CheckOrderBox

import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import { saveShippingAddress } from '../actions/cartActions'

const ShippingScreen = ({ history }) => {
  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  const [name, setName] = useState(shippingAddress.name)
  const [address, setAddress] = useState(shippingAddress.address)
  const [insideDhaka, setInsideDhaka] = useState(shippingAddress.insideDhaka|| "No")
  // const [city, setCity] = useState(shippingAddress.city)
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
  const [phone, setPhone] = useState(shippingAddress.phone)
  const [country, setCountry] = useState("Bangladesh")

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(saveShippingAddress({ phone, address, name, postalCode, country, insideDhaka }))
    history.push('/placeorder')
  }

  return (
    <FormContainer>
      <CheckoutSteps step1 step2 />
      <h1>Shipping</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='name'>
          <Form.Label>Enter your full name in english {"( ইংরেজিতে আপনার পুরো নাম লিখুন )"}</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Your name'
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId='phone'>
          <Form.Label>Enter your phone number in english {"( ইংরেজিতে আপনার ফোন নম্বর লিখুন )"}</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Phone Number'
            value={phone}
            required
            onChange={(e) => setPhone(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId='address'>
          <Form.Label>Enter your full address {"( আপনার সম্পূর্ণ ঠিকানা লিখুন )"}</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter address'
            value={address}
            required
            onChange={(e) => setAddress(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId='address'>
          <Form.Label>If your house is inside dhaka kindly select yes.. {"( আপনার বাসা ঢাকার ভিতরে হলে হ্যাঁ নির্বাচন করুন। )"}</Form.Label>
          <Form.Control
            as='select'
            value={insideDhaka}
            onChange={(e) => setInsideDhaka(e.target.value)}
          >
            <option value="No">No {"( না )"}</option>
            <option value="Yes">Yes {"( হ্যাঁ )"}</option>
          </Form.Control>
        </Form.Group>

        <Button type='submit' variant='primary'>
          Continue
        </Button>
      </Form>
    </FormContainer>
  )
}

export default ShippingScreen

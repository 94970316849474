import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Footer = () => {
  return (
    <footer className="footer-one">
      <div className="inner-footer">
        <div className="container">
          <div className="footer-top col-lg-12 col-xs-12">
            <div className="row">
              <div className="tiva-html col-lg-4 col-md-12 col-xs-12">
                <div className="block">
                  <div className="block-content">
                    <p className="logo-footer">
                      {/* <img src="img/home/logo.png" alt="img"/> */}
                      BeguniPik
                    </p>
                    <p className="content-logo">
                      Say goodbye to syndicate pricing, buy your favorite products at affordable prices.
                    </p>
                  </div>
                </div>
                <div className="block">
                  <div className="block-content">
                    <ul>
                      <li>
                        <a href="/products">View All Products</a>
                      </li>
                      {/* <li>
                        <a href="#">Reasons to shop</a>
                      </li>
                      <li>
                        <a href="#">What our customers say</a>
                      </li>
                      <li>
                        <a href="#">Meet the teaml</a>
                      </li>
                      <li>
                        <a href="#">Contact our buyers</a>
                      </li> */}
                    </ul>
                  </div>
                </div>
                {/* <div className="block">
                  <div className="block-content">
                    <p className="img-payment ">
                      <img className="img-fluid" src="img/home/payment-footer.png" alt="img" />
                    </p>
                  </div>
                </div> */}
              </div>
              <div className="tiva-html col-lg-4 col-md-6">
                <div className="block m-top">
                  <div className="title-block">
                    Contact Us
                  </div>
                  <div className="block-content">
                    <div className="contact-us">
                      <div className="title-content">
                        <i className="fa fa-home" aria-hidden="true"></i>
                        <span>Address :</span>
                      </div>
                      <div className="content-contact address-contact">
                        <p>Sajjankanda, Rajbari Sadar, Bangladesh</p>
                      </div>
                    </div>
                    {/* <div className="contact-us">
                      <div className="title-content">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                        <span>Email :</span>
                      </div>
                      <div className="content-contact mail-contact">
                        <p>support@domain.com</p>
                      </div>
                    </div> */}
                    <div className="contact-us">
                      <div className="title-content">
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        <span>Hotline :</span>
                      </div>
                      <div className="content-contact phone-contact">
                        <p>+88 01835 158 205</p>
                        <p>+88 01765 438 567</p>
                      </div>
                    </div>
                    <div className="contact-us">
                      <div className="title-content">
                        <i className="fa fa-clock" aria-hidden="true"></i>
                        <span>Opening Hours :</span>
                      </div>
                      <div className="content-contact hours-contact">
                        <p>Monday - Sunday / 08.00AM - 19.00</p>
                        <span>(Except Holidays)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tiva-modules col-lg-4 col-md-6">
                {/* <div className="block m-top">
                  <div className="block-content">
                    <div className="title-block">Newsletter</div>
                    <div className="sub-title">Sign up to our newsletter to get the latest articles, lookbooks voucher codes direct
                      to your inbox
                    </div>
                    <div className="block-newsletter">
                      <form action="#" method="post">
                        <div className="input-group">
                          <input type="text" className="form-control" name="email" value="" placeholder="Enter Your Email" />
                          <span className="input-group-btn">
                            <button className="effect-btn btn btn-secondary " name="submitNewsletter" type="submit">
                              <span>subscribe</span>
                            </button>
                          </span>
                        </div>
                        <input type="hidden" name="action" value="0" />
                      </form>
                    </div>
                  </div>
                </div>
                <div className="block m-top1">
                  <div className="block-content">
                    <div className="social-content">
                      <div className="title-block">
                        Follow us on
                      </div>
                      <div id="social-block">
                        <div className="social">
                          <ul className="list-inline mb-0 justify-content-end">
                            <li className="list-inline-item mb-0">
                              <a href="#" target="_blank">
                                <i className="fa fa-facebook"></i>
                              </a>
                            </li>
                            <li className="list-inline-item mb-0">
                              <a href="#" target="_blank">
                                <i className="fa fa-twitter"></i>
                              </a>
                            </li>
                            <li className="list-inline-item mb-0">
                              <a href="#" target="_blank">
                                <i className="fa fa-google"></i>
                              </a>
                            </li>
                            <li className="list-inline-item mb-0">
                              <a href="#" target="_blank">
                                <i className="fa fa-instagram"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="block m-top1">
                  <div className="block-content">
                    <div className="payment-content">
                      <div className="title-block">
                        Payment accept
                      </div>
                      <p>
                      We accept bKash, Roket, Nagad
                      </p>
                      <div className="payment-image">
                        {/* <img className="img-fluid" src="img/home/payment.png" alt="img" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="tiva-copyright">
        <div className="container">
          <div className="row">
            <div className="text-center col-lg-12 ">
              <span>
                Copyright &copy; BeguniPik
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>

  )
}

export default Footer

import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Button, Row, Col } from 'react-bootstrap'
import Rating from './Rating'
import { useDispatch } from 'react-redux'

import { addToCart, removeFromCart } from '../actions/cartActions'
const Product = ({ product }) => {

  const dispatch = useDispatch()
  const addToCartHandler = () => {
    dispatch(addToCart(product._id, 1))
  }

  return (
    <Card className='my-3 p-3 rounded'>
      <Link to={`/product/${product._id}`}>
        <Card.Img src={product.image} variant='top' />
      </Link>

      <Card.Body>
        <Link to={`/product/${product._id}`}>
          <Card.Title as='div'>
            <strong>{product.name}</strong>
          </Card.Title>
        </Link>

        <Card.Text as='div'>
          <Rating
            value={product.rating}
            text={`${product.numReviews} reviews`}
          />
        </Card.Text>

        <Card.Text as='h3'>${product.price}</Card.Text>
        {product.countInStock === 0 && <Card.Text as='p'>Out Of Stock</Card.Text>}
        <Row>
          <Col xs={6} >
            <Button
              onClick={addToCartHandler}
              className='btn-block product-action-btn'
              type='button'
               variant="outline-dark"
              disabled={product.countInStock === 0}
            >
              Add To Cart
            </Button>
          </Col>
          <Col xs={6} >
            <Link to={`/cart/${product._id}?qty=1`}>
              <Button
                className='btn-block product-action-btn'
                type='button'
                disabled={product.countInStock === 0}
              >
                Order Now
              </Button>
            </Link>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default Product

import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Row, Col, ListGroup, Image, Card, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import CheckoutSteps from '../components/CheckoutSteps'
import { createOrder } from '../actions/orderActions'
import { ORDER_CREATE_RESET } from '../constants/orderConstants'
import { USER_DETAILS_RESET } from '../constants/userConstants'

const PlaceOrderScreen = ({ history }) => {
  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart)
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  if (!cart.shippingAddress.address) {
    history.push('/shipping')
  }
  //   Calculate prices
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  )
  // cart.shippingPrice = addDecimals(cart.itemsPrice > 150 ? 0 : 150)
  cart.shippingPrice = cart?.shippingAddress?.insideDhaka == "Yes" ? 50 : 120
  // cart.taxPrice = addDecimals(Number((0.15 * cart.itemsPrice).toFixed(2)))
  cart.taxPrice = 0
  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.taxPrice)
  ).toFixed(2)

  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success, error, loading } = orderCreate

  useEffect(() => {
    if (success) {
      history.push(`/order/${order._id}`)
      dispatch({ type: USER_DETAILS_RESET })
      dispatch({ type: ORDER_CREATE_RESET })
    }
    // eslint-disable-next-line
  }, [history, success])

  const placeOrderHandler = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
      })
    )
  }

  return (
    <>
      <CheckoutSteps step1 step2 step3 />
      <Row>
        <Col md={8}>

          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Shipping</h2>
              <p>
                <strong>Address:</strong>
                {cart.shippingAddress.address}, {cart.shippingAddress.city}{' '}
                {cart.shippingAddress.postalCode},{' '}
                {cart.shippingAddress.country}
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Payment Method</h2>
              <strong>Method: </strong>
              {/* {cart.paymentMethod} */}
              Cash On Delivery
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Order Items</h2>
              {cart.cartItems.length === 0 ? (
                <Message>Your cart is empty</Message>
              ) : (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Image</th>
                      <th>Product</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart.cartItems.map((item, index) => (
                      <tr key={item.product}>
                        <td>{index + 1}</td>
                        <td>
                          <Image src={item.image} alt={item.name} fluid rounded style={{ width: "40px" }} />
                        </td>
                        <td><Link to={`/product/${item.product}`}>{item.name}</Link></td>
                        <td>
                          {item.qty} x ${item.price} = ${item.qty * item.price}
                        </td>
                      </tr>
                    ))
                    }
                  </tbody>
                </Table>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Items</Col>
                  <Col>${cart.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Shipping</Col>
                  <Col>${cart.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Tax</Col>
                  <Col>${cart.taxPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  <Col>${cart.totalPrice}</Col>
                </Row>
              </ListGroup.Item>

              {error && <ListGroup.Item><Message variant='danger'>{error}</Message>
              </ListGroup.Item>
              }
              <ListGroup.Item>
                {
                  !userInfo &&
                  <p>I don't want to create account. I want to directly place an order.
                    {" ( আমি অ্যাকাউন্ট তৈরি করতে চাই না। আমি সরাসরি অর্ডার করতে চাই। )"}
                  </p>
                }
                <Button
                  type='button'
                  className='btn-block'
                  disabled={cart.cartItems === 0 || loading || success}
                  onClick={placeOrderHandler}
                >
                  Place Order
                </Button>
              </ListGroup.Item>
              {
                !userInfo &&
                <ListGroup.Item>
                  <p>You can place an order without creating any account
                    {" ( আপনি কোনো অ্যাকাউন্ট তৈরি না করেই অর্ডার করতে পারেন )"}
                  </p>
                  <p>But if you want to see your orders in future, you need to login or create an account and then place an order.
                    {" ( কিন্তু আপনি যদি ভবিষ্যতে আপনার অর্ডার দেখতে চান তবে আপনাকে লগইন করতে হবে বা একটি অ্যাকাউন্ট তৈরি করতে হবে এবং তারপরে একটি অর্ডার দিতে হবে। )"}
                  </p>
                  <Link to={`/login?redirect=/placeorder`}>
                    <Button
                      type='button'
                      className='btn-block'
                    >
                      Login
                    </Button>
                  </Link>
                  <Link to={`/register?redirect=/placeorder`}>
                    <Button
                      type='button'
                      className='btn-block mt-2'
                    >
                      Create An Account
                    </Button>
                  </Link>
                </ListGroup.Item>
              }

            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default PlaceOrderScreen
